import React from 'react'
import Layout from '../components/Layout'

 const ContactStatus = () => {
    return (
        <Layout
        title='Contact Status'
        description='Interaction. Engineering. Design.'
        >
                <div className='contact-status'>
                    Thank you for contacting us. We will respond to you shortly!
                </div>
        </Layout>
    )
}


export default ContactStatus
